<template>
  <router-view :key="$route.fullPath" />
  <!-- <notifications /> -->
  <notifications group="auth" position="top center" />
  <notifications group="app" position="bottom right" />
</template>
<script>
export default {
  name: "App",
  created() {
    // Listen for service worker updates
    window.addEventListener('swUpdated', this.showRefreshUI);
  },
  methods: {
    showRefreshUI(e) {
      // Show your refresh UI (e.g., a button or modal)
      console.log('New version available:', e.detail);
    }
  }
};
</script>
<style>
</style>
