import $ from 'jquery';
import 'magnific-popup';
import Plyr from 'plyr';

const VuePlugin = {
  install(app) {
    app.config.globalProperties.$preloader = () => {
      $(window).on("load", function () {
        $("#preloader").delay(350).fadeOut("slow");
      });
    };

    app.config.globalProperties.$toggleDarkMode = () => {
      $(".toggle-switch input").on("change", function () {
        if (this.checked) {
          $.cookie("layout_mood", "dark", { expires: 365, path: "/" });
          $("#template-color").attr("href", "static/css/dark.css");
          $(".custom-logo-link img").attr("src", "static/images/logo-alt.png");
        } else {
          $.removeCookie("layout_mood", { path: "/" });
          $("#template-color").attr("href", "");
          $(".custom-logo-link img").attr("src", "static/images/logo.png");
        }
      });

      if ($.cookie("layout_mood") == "dark") {
        $('.toggle-switch input[type="checkbox"]').prop("checked", true);
        $("#template-color").attr("href", "static/css/dark.css");
        $(".custom-logo-link img").attr("src", "static/images/logo-alt.png");
      }
    };

    app.config.globalProperties.$initPopupVideo = () => {
      $(".popup-video").magnificPopup({
        disableOn: 700,
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
      });
    };

    app.config.globalProperties.$initCountdown = () => {
      $("[data-date]").each(function () {
        const countDownDate = new Date($(this).data("date")).getTime();
        const counterID = "#" + this.id;
        const x = setInterval(() => {
          const now = new Date().getTime();
          const distance = countDownDate - now;
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          $(counterID).html(`
            <div class="countdown-date">
                <span>${hours}</span> <b>:</b> <span>${minutes}</span> <b>:</b> <span>${seconds}</span>
            </div>
          `);
          if (distance < 0) {
            clearInterval(x);
            $(counterID).html("EXPIRED");
          }
        }, 1000);
      });
    };

    app.config.globalProperties.$initPlyr = () => {
      Plyr.setup(".viewtube-player-single");
      Plyr.setup(".viewtube-player", {
        controls: ["play-large", "fullscreen", "pip"],
      });

      $(".video-item-card").hover(
        function () {
          $(".viewtube-player", this).get(0).play();
        },
        function () {
          $(".viewtube-player", this).get(0).pause();
        }
      );
    };

    app.config.globalProperties.$initSearch = () => {
      $(".keyword").on("keyup", function () {
        $(".datafetch").html("").show();
        const searchField = $(".keyword").val();
        const expression = new RegExp(searchField, "i");
        $.getJSON("data.json", function (data) {
          $.each(data, function (key, value) {
            if (value.title.search(expression) != -1) {
              $(".datafetch").append(
                `<li class="list-group-item link-class">
                  <a href="video.html?id=${value.id}">
                    <img src="${value.image}" height="40" width="40" class="img-thumbnail" />
                    ${value.title}
                  </a>
                </li>`
              );
            }
            return key < 9;
          });
        });
      });

      $(document).on("click", function () {
        $(".datafetch").hide();
      });
    };

    app.config.globalProperties.$initVideoPage = () => {
      const getUrlVars = () => {
        const vars = [];
        const hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
        for (let i = 0; i < hashes.length; i++) {
          const hash = hashes[i].split("=");
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
        return vars;
      };

      $.getJSON("data.json", function (data) {
        $.each(data, function (key, value) {
          if (value.id == getUrlVars()["id"]) {
            $(".single-video .video-entry-title").text(value.title);
            return;
          }
        });
      });
    };

    app.config.globalProperties.$initDropdowns = () => {
      $(".my-account-button").on("click", function (event) {
        event.preventDefault();
        const dropdown = $(this).closest(".my-account-widget");
        dropdown.toggleClass("open");
      });

      $(document).on("click", function (event) {
        $(".my-account-widget")
          .not($(event.target).closest(".my-account-widget"))
          .removeClass("open");
      });

      $(".departments-menu-button").on("click", function (event) {
        event.preventDefault();
        const dropdown = $(this).closest(".departments-container");
        dropdown.toggleClass("open");
      });

      $(document).on("click", function (event) {
        $(".departments-container")
          .not($(event.target).closest(".departments-container"))
          .removeClass("open");
      });
    };

    app.config.globalProperties.$initSidebar = () => {
      $(".sidebar-toggle").on("click", function (event) {
        event.preventDefault();
        const dropdown = $(".sidebar-menu");
        dropdown.toggleClass("open");
      });

      $(".single-video .sidebar-menu, .page-template-video-upload .sidebar-menu").removeClass("open");
    };

    app.config.globalProperties.$initScroll = () => {
      $(window).on("scroll", function () {
        if ($(document).scrollTop() > $(".main-container").height() - $(".site-footer").height() + 100) {
          $(".desktop-menu").css({ position: "sticky", top: 0 });
        } else {
          $(".desktop-menu").css({ position: "fixed", top: "unset" });
        }
      });

      $(window).on("scroll", function () {
        if ($(this).scrollTop() >= 700) {
          $("#backtotop").fadeIn(500);
        } else {
          $("#backtotop").fadeOut(500);
        }
      });

      $("#backtotop").on("click", function () {
        $("body,html").animate({ scrollTop: 0 }, 500);
      });
    };

    app.config.globalProperties.$initFileUpload = () => {
      $("#video-file, #thumbnail-file").on("change", function (e) {
        const file = this.files[0];
        if (file) {
          alert(`Your File "${file.name}" Uploaded!`);
        } else {
          alert("Invalid file type");
        }
      });

      $(".dropzone.video, .dropzone.thumbnail")
        .on("drag dragstart dragend dragover dragenter dragleave drop", function (e) {
          e.preventDefault();
          e.stopPropagation();
        })
        .on("dragover dragenter", function () {
          $(this).addClass("is-dragover");
        })
        .on("dragleave dragend drop", function () {
          $(this).removeClass("is-dragover");
        })
        .on("drop", function (e) {
          const file = e.originalEvent.dataTransfer.files[0];
          if (file) {
            alert(`Your File "${file.name}" Uploaded!`);
          } else {
            alert("Invalid file type");
          }
        });
    };

    app.config.globalProperties.$initModal = () => {
      const storage = window.localStorage;
      $(window).on("load", function () {
        setTimeout(function () {
          if (storage.getItem("modal_stop") !== "true") {
            $("#newsletterModal").modal("show");
          }
        }, $("#newsletterModal").data("time"));
      });

      $("#dont-show").on("click", function () {
        if ($(this).is(":checked")) {
          storage.setItem("modal_stop", true);
        }
      });

      $("#dont-show-hour").on("click", function () {
        storage.setItem("modal_stop", true);
      });
    };

    app.config.globalProperties.$initMobileMenu = () => {
      if ($(".off-canvas-menu").length) {
        const mobileMenuContent = $(".desktop-menu>ul").html();
        $(".off-canvas-menu .navigation").append(mobileMenuContent);
        $(".mobile-nav-toggler").on("click", function () {
          $("body").addClass("off-canvas-menu-visible");
        });
        $(".off-canvas-menu .menu-backdrop, .off-canvas-menu .close-btn").on("click", function () {
          $("body").removeClass("off-canvas-menu-visible");
        });
      }

      $(".off-canvas-menu li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>'
      );

      $(".off-canvas-menu li.menu-item-has-children .dropdown-btn").on("click", function () {
        $(this).prev("ul").slideToggle(500);
        $("body").removeClass("off-canvas-menu-visible");
      });
    };

  },
};

export default VuePlugin;