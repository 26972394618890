import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/seriesContent/:series_id',
    name: 'SeriesContent',
    component: () => import(/* webpackChunkName: "seriesContent" */ '../views/page/SeriesContentView.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  
  {
    path: '/Speaking/:cat_id',
    name: 'speaking',
    component: () => import(/* webpackChunkName: "Speaking" */ '../views/page/SpeakingView.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/LoginView.vue')
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/user/SubscribeView.vue')
  },
  {
    path: '/un-subscribe',
    name: 'un-subscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/user/UnsubscribeView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "userProfile" */ '../views/user/UserProfileView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/contents/:content_id',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/page/ContentsView.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/searchs/:search_id',
    name: 'Searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/page/SearchsView.vue'),
    props: true
  },
  {
    path: '/contact-us',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/page/ContactsView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/scheduled',
    name: 'scheduled',
    component: () => import(/* webpackChunkName: "scheduled" */ '../views/page/ScheduledView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: () => import(/* webpackChunkName: "favorite" */ '../views/page/FavoriteView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFounds',
    component: () => import(/* webpackChunkName: "notFounds" */ '../views/page/NotFoundsView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

// router.beforeEach((to, from, next ) => {
//   const { $cookie } = router.app.config.globalProperties
//    if(to.meta.IsAuth) {
//     if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
//       next();
//     } else {
//       next(window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self"));
//       // next();
//     }
//    } else {
//      next();
//    }
// })

router.beforeEach((to, from, next) => {
  const { $cookie } = router.app.config.globalProperties;
  
  // Check if the route requires authentication
  if (to.meta.IsAuth) {
    // Check if necessary cookies are available
    if ($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();  // Allow navigation to the route
    } else {
      // Redirect to a specific URL if cookies are missing
      // console.warn("Missing authentication cookies. Redirecting...");
      next(window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self"));
    }
  } else {
    // Allow navigation to non-authenticated routes
    next();
  }
});


export default router